import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-white text-black">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-10">
          Computer Science graduate with over 2 years of experience in the IT
          industry with proven knowledge of cloud architectures, database
          systems, programming languages, algorithms, data structures, among
          other important topics. Fueled by an endless desire to learn and
          improve. My ultimate goal is to support the company in realizing its
          business objectives while staying true to the vision and values.
        </p>
        <br />
      </div>
    </div>
  );
};

export default About;
